import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import * as styles from './Breadcrumb.module.css';
import { useLocation } from '@reach/router';

// Breadcrumbアイテムの型定義
interface BreadcrumbItem {
  pathname: string;
  crumbLabel: string;
}

interface BreadcrumbProps {
  crumbs: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
  useEffect(() => {
    const breadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: crumbs.map((crumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: crumb.crumbLabel,
        item: `${typeof window !== 'undefined' ? window.location.origin : ''}${
          crumb.pathname
        }`,
      })),
    };
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(breadcrumbList);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [crumbs]);
  const location = `${
    typeof window !== 'undefined' ? window.location.origin : ''
  }`;

  return (
    <nav aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {crumbs.map((crumb, index) => (
          <li key={index}>
            {crumb.pathname === location ? (
              <span>{crumb.crumbLabel}</span>
            ) : (
              <Link to={crumb.pathname}>{crumb.crumbLabel}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
