import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './AboutUs.module.css';

type ColorType = 'primary' | 'secondary';

type AboutUsProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  subTitle: string;
  sectionId: string;
  colorType: ColorType;
  items: {
    title: string;
    description: string;
  }[];
};

const AboutUs: React.FC<AboutUsProps> = ({
  imageSrc,
  imageAlt,
  title,
  subTitle,
  colorType,
  items,
  sectionId,
}) => {
  // 改行文字をHTMLの<br />タグに変換する関数
  const formatDescription = (description: string) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  let featureSectionClass = styles.featureSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    featureSectionClass = styles.featureSectionPrimary;
  } else if (colorType === 'secondary') {
    featureSectionClass = styles.featureSectionSecondary;
  }

  return (
    <ScrollAnimation>
      <section className={featureSectionClass} id={sectionId}>
        <div className={styles.featureSectionInner}>
          <h2 className={styles.head}>
            <span className={styles.headText}>{title}</span>
            <span className={styles.headSubtext}>{subTitle}</span>
          </h2>
          <img className={styles.image} src={imageSrc} alt={imageAlt} />
          <div className={styles.content}>
            {items.map((item, index) => (
              <dl key={index} className={styles.companyDetails}>
                <dt>{item.title}</dt>
                <dd>{formatDescription(item.description)}</dd>
              </dl>
            ))}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default AboutUs;
