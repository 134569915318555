// extracted by mini-css-extract-plugin
export var companyDetails = "AboutUs-module--companyDetails--ceb1a";
export var content = "AboutUs-module--content--2f290";
export var description = "AboutUs-module--description--c7c04";
export var featureSection = "AboutUs-module--featureSection--3594b";
export var featureSectionInner = "AboutUs-module--featureSectionInner--19a80";
export var featureSectionPrimary = "AboutUs-module--featureSectionPrimary--954d9";
export var featureSectionSecondary = "AboutUs-module--featureSectionSecondary--a3618";
export var head = "AboutUs-module--head--de9f3";
export var headSubtext = "AboutUs-module--headSubtext--b80f8";
export var headText = "AboutUs-module--headText--dec9b";
export var image = "AboutUs-module--image--2cb9c";
export var title = "AboutUs-module--title--845c2";